<template>
    <div class="main-container">
        <Header />

        <HeroOne :sliderData="data.heroThree" />

        <Feature />

        <AboutSectionTwo />

        <ServiceTabs />

        <Testimonial />

        <ProjectGallery :latestProductsRandom="latestProductsRandom" />

        <BrandCarousel addClass="grey-bg" />

<!--        <BlogSection />-->

        <Footer/>
        
        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import HeroOne from '@/components/sections/HeroOne';
    import Feature from '@/components/Feature';
    import AboutSectionTwo from '@/components/sections/AboutSectionTwo';
    import ServiceTabs from '@/components/sections/ServiceTabs';
    import Testimonial from '@/components/Testimonial';
    import ProjectGallery from '@/components/sections/ProjectGallery';
    import BrandCarousel from '@/components/BrandCarousel';
    import BlogSection from '@/components/sections/BlogSection';
    import Footer from '@/components/Footer';
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';
    import axios from '@/axios.js'
    // import moduleCategoryList from '@/store/titos-category/moduleCategoryList'

    import data from '../data/slider.json'
    export default {
        data () {
            return {
                data,
              latestProductsRandom:[]
            }
        },
        components: {
            Header,
            HeroOne,
            Feature,
            AboutSectionTwo,
            ServiceTabs,
            Testimonial,
            ProjectGallery,
            BrandCarousel,
            BlogSection,
            Footer,
            OffCanvasMobileMenu
        },
        metaInfo: {
            title: 'Titos',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        },
      created () {
        axios.get('/api/latest_random_products')
            .then((response) => {
              console.log(response.data.data)

              this.latestProductsRandom = response.data.data
              // commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
              // resolve(response)
            })
            .catch((error) => {
              console.log(error)
            })
      //     if (!moduleCategoryList.isRegistered){
      //       this.$store.registerModule('categoryList', moduleCategoryList)
      //       moduleCategoryList.isRegistered = true
      //     }
      //
      //   this.$store.dispatch('categoryList/fetchDataListItems')
      // },
      // computed: {
      //     Category(){
      //       // console.log(this.$store.state.categoryList.Category)
      //       return this.$store.state.categoryList.Category
      //     }
      }
    }
</script>