<template>
    <div class="project-gallery-area section-space--inner--120">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- section title -->
                    <div class="section-title-area text-center section-space--bottom--50">
                        <h2 class="section-title">Latest Products</h2>
                        <p class="section-subtitle">You can browse products from the products section you will find more information</p>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="project-gallery-wrapper">
                        <div class="row">
                            <div :class="(index == 0 || index == 5) ? 'col-md-5' : 'col-md-3 col-mobile-6'" v-for="(item, index) in latestProductsRandom">
                              <div class="single-gallery-project">
                                <div class="single-gallery-project__image">
                                  <img :src="BaseUrl + item.image" :width="(index == 0 || index == 5) ? '560px' : '265px'" height="307px" class="img-fluid" alt="">
                                </div>
                                <div class="single-gallery-project__content">
                                  <h4 class="title">{{ item.title }}</h4>
<!--                                  <router-link to="/project-details">Read More</router-link>-->
                                </div>
                              </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      data(){
        return {
          // BaseUrl: process.env.VUE_APP_URL
          BaseUrl: 'https://titos.com.es/backend/images/upload/'
        }
      },
        props: [
          'latestProductsRandom'
        ]
    };
</script>

<style lang="scss" scoped>


.single-gallery-project {
  position: relative;
  margin-bottom: 30px;

  &__content {
    position: absolute;
    bottom: 0;
    left: 20px;
    padding: 20px;
    z-index: 1;
    visibility: visible;
    opacity: 0.7;
    transition: 0.3s;
    max-width: 220px;
  }
}

</style>